<script>
export default {
  name: "links",
  data: () => ({}),
  props: {
    content: Object
  }
}
</script>

<template>
  <div class="links">

    <h3 v-html="content.title"></h3>

    <p class="notes" v-html="content.description"></p>

    <template v-for="(key, value) in content.blocks">
      <p class="link-item">
        <span class="num" v-html="value + 1"></span>
        <span v-html="key.description"></span>
      </p>
    </template>

  </div>
</template>

<style scoped lang="scss">
.links {
  background: #EEE;
  padding: 40px;

  @media screen and (max-width: 1024px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  h3 {
    color: #00A03B;
    font-family: "Commissioner-Medium";
    font-size: 20px;
    margin-bottom: 25px;
  }

  .notes {
    color: #545454;
    font-size: 13px;
    font-family: "Commissioner-Regular";

    margin-bottom: 20px;
  }

  .link-item {
    margin-bottom: 20px;
    color: #545454;
    font-size: 13px;
    font-family: "Commissioner-Regular";
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    ::v-deep a {
      text-decoration: none;
      span{
        text-decoration: underline;
      }
    }

    .num {
      color: #FFF;
      font-size: 13px;
      font-family: "Commissioner-Regular";
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 2px 1px 2px;
      background: #00A03B;
      min-width: 21px;
      min-height: 21px;
      max-width: 21px;
      max-height: 21px;
      margin-right: 15px;
    }
  }
}

</style>